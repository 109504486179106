#contact {
  padding: 0em 0 0;
  font-size: 18px;
}

.contactContainer {
  display: flex;
  text-align: center;
  width: 100%;
  padding: 0 4em;
}

.contactInfo {
  width: 100%;
  /* background-color: #ec0000; */
}

.contactInfo p {
  margin: 2em 0;
}

.contactInfo a {
  margin: 2em;

  text-decoration: none;
}

.contactButton {
  background-color: #700000;
  color: #fff;
  padding: 0.5em 1em;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.7);
}

.socials {
  display: flex;
  justify-content: center;
  gap: 1em;
}

@media screen and (max-width: 768px) {
  #contact {
    padding: 0 0 4em;
  }

  .contactContainer {
    flex-direction: column;
    padding: 0;
  }

  .contactForm {
    width: 100%;
    padding: 2em 1em;
  }

  .contactInfo {
    width: 100%;
  }
}
