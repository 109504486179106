footer {
  justify-content: space-between;
  align-items: center;
  height: 80px;
  text-align: center;
  width: 100%;
}

footer a {
  text-decoration: none;
}

footer button {
  background: #700000;
  color: white;
  border: none;
  padding: 0.5em 1em;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.7);
  border-radius: 4px;
  cursor: pointer;
}

dialog button {
  background: #700000;
  color: white;
  border: none;
  padding: 0.5em 1em;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.7);
  border-radius: 4px;
  cursor: pointer;
  margin: 0 24px;
}

.emailWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  gap: 1em;
  margin-bottom: 1em;
}

.passwordWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  gap: 1em;
  margin-bottom: 1em;
}

.changes {
  margin: 1em auto 3em;
  display: flex;
  justify-content: center;
  display: none;
}

.changes button {
  margin: 0 1em;
}

.footerLinksWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding-bottom: 3em;

  background: hsla(42, 59%, 92%, 1);
}

.footerLink {
  display: flex;
  width: 100%;
  justify-content: space-around;
  align-items: center;
  flex-wrap: wrap;
}
