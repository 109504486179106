.hero {
  position: relative;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  text-align: center;
  border: #333 1px solid;
}

.hero::before {
  content: "";
  background: url("../../archive/IMG_0953_upscaled.jpg") no-repeat center
    center/cover;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
  /* filter: brightness(0.7) blur(1px); */
}

.splashLogo {
  width: 100%;
  max-width: 800px;
  margin: 0em auto 0em;
  background-color: rgb(247, 239, 222, 100%);
  border: none;
  border-radius: 12px;
  animation: fadeInUp 1s ease-out;
}

@keyframes fadeInUp {
  0% {
    opacity: 0.8;
    transform: translateY(100px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}
