.about {
  display: block;
  margin: 0 auto;
  text-align: center;
  padding: 4em 4em 2em;
  max-width: 1400px;
  color: #700000;
  font-size: 30px;
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.5);
  line-height: 1.2;
}

.about input {
  font-size: 20px;
  padding: 0.5em;
  margin: 1em;
  width: 100%;
}

.about h4 {
  margin-top: 0;
}

.joke {
  font-size: 20px;
  margin-top: 1em;
}

@media screen and (max-width: 768px) {
  .about {
    padding: 1em;
    font-size: 20px;
  }
}
