.navbar {
  box-sizing: border-box;
  display: flex;
  text-align: center;
  align-items: center;
  height: 75px;
  background-color: #333;
  justify-content: space-between;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 1;
  padding: 0 20px;
  background-color: #f7efdf;
  border-bottom: #333 1px solid;
}

.navbar-ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
  overflow: hidden;
  width: calc(100% / 3);
}

.navbar-ul li {
  float: left;
  font-size: 28px;
  padding: 10px 0;
  border: none;
  border-radius: 4px;
}

.navbar-ul li a {
  color: black;
  text-align: center;
  padding: 14px 16px;
  text-decoration: none;
}

.navbar-ul li:hover {
  background-color: #700000;
}

.navbar-ul li:hover a {
  color: #f7efdf;
}

.navbar-ul .active {
  background-color: #4caf50;
}

.logoText {
  font-size: 30px;
  color: black;
  width: calc(100% / 3);
}

.bookingButton {
  width: calc(100% / 3);
  text-align: right;
}

.bookingButton button {
  background-color: #700000;
  border: none;
  border-radius: 4px;
  color: #f7efdf;
  padding: 8px 32px 8px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 32px;
  margin: 4px 2px;
  cursor: pointer;
}

.bookingButton button:hover {
  background-color: #f7efdf;
  border: 2px solid #700000;

  a {
    color: #700000;
  }
}

.bookingButton button a {
  color: #f7efdf;
  text-decoration: none;
}

.bookingButton button a:hover {
  color: #700000;
}

.hamburger {
  align-items: center;
  line-height: 0px;
  display: none;
}

.drawer {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-color: #f7efdf;
  z-index: 1;
}

.drawer {
  transition: transform 0.3s ease-in-out;
  transform: translateX(-100%);
}

@media screen and (max-width: 1200px) {
  .navbar-ul {
    display: none;
  }
  .logoText {
    display: none;
  }
  .bookingButton {
    width: 100%;
    text-align: center;
  }
}
