.menu {
  width: 100%;
  color: #700000;
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.5);
  padding: 4em 0;
}

.sectionTitle {
  font-size: 4em;
}

.menuItem {
  display: flex;
  justify-content: space-around;
}

.menuItem .menu-item-contents input {
  width: 100%;
  height: 30px;
  text-align: center;
  font-size: 20px;
  resize: both;
}

.menuItem div {
  width: 500px;
}

.menuItem input {
  width: 60px;
  height: 30px;
  text-align: center;
  font-size: 20px;
  resize: both;
}

.menuItem span {
  font-size: 1.5em;
  font-weight: 600;
}

.createSectionButton {
  background: green;
  color: white;
  border: none;
  padding: 0.5em 1em;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.7);
  border-radius: 4px;
  cursor: pointer;
}

.createMenuItemButton {
  background: green;
  color: white;
  border: none;
  padding: 0.5em 1em;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.7);
  border-radius: 4px;
  cursor: pointer;
  display: flex;
  margin: 1em auto;
}

.deleteMenuItemButton {
  background: red;
  color: white;
  border: none;
  padding: 0.5em 1em;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.7);
  border-radius: 4px;
  cursor: pointer;
  display: flex;
  margin: 1em 0;
}

.otherListings {
  text-align: center;
}

@media screen and (max-width: 768px) {
  .menu {
    padding: 0;
  }
  .menuItem div {
    width: 300px;
  }
}
