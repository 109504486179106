/* Carousel.js */

.Carousel {
  padding: 1em 1em;
  margin: 1em 0em;
  width: 100%;
  display: flex;
  overflow-x: scroll;
  /* background-color: #000000; */
}

.Carousel img {
  padding: 0.25em;
  width: 250px;
  height: 250px;
  border-radius: 0.5em;
  margin: 0 auto;
  filter: contrast(115%);
}

.card {
  min-width: 275px;
  height: 275px;
  margin: 0em 1em;
  border: 1px solid black;
  display: flex;
  text-align: center;
  align-items: center;
  box-shadow: 0px 0px 8px 0px #700000;
  border-radius: 0.5em;
}

.cardBody p {
  font-size: 16px;
  margin: 0em 1em;
  color: black;
}

.Carousel::-webkit-scrollbar {
  background: gray;
  height: 8px;
  cursor: pointer;
}

.Carousel::-webkit-scrollbar-thumb {
  border-radius: 2em;
  background: black;
}

@keyframes scroll {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(calc(-250px * 12.28));
  }
}

.slide-track {
  animation: scroll 40s linear infinite;
  display: flex;
  width: calc(250px * 14);
}

.slide-track:hover,
.slide-track:focus {
  animation-play-state: paused;
}
